import { Link } from 'react-navi';

import { formatTypographyToFont } from 'helpers/typography';

import { pulseAppearAnimation } from 'components/globalStyles/animation';

import styled from 'styled-components/macro';

export const TabsContainer = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 55px;
  background: ${({ theme }) => theme.colors.background.foreground};
  border-top: 1px solid ${({ theme }) => theme.colors.border.system};
  z-index: revert-layer;
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    display: flex;
  }
`;

export const TabLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  min-width: 94px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing['s-06']};
  cursor: pointer;
  padding: 8px 12px;
  transition: 0.3s ease-in-out all;
  span {
    font: ${({ theme }) =>
      formatTypographyToFont(theme.typography['overline-x-small'])};
    color: ${({ theme }) => theme.colors.text.placeholder};
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    min-width: 84px;
  }
  &:hover {
    span {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
  &.active {
    border-top: 2px solid ${({ theme }) => theme.colors.border.focus};
    span {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
  &.active {
    svg {
      animation: ${pulseAppearAnimation} 0.6s ease-in-out;
    }
  }
`;
