import React, { Fragment, useCallback, useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useApi } from 'hooks/api';
import { useOutsideClickVisibility } from 'hooks/visibility';

import { editUser } from 'api/user';

import { showTooltip } from 'helpers/tooltip';
import { translationFactory } from 'helpers/trans';

import { Icon } from 'components/UI/Icon';
import { ModalSheet } from 'components/UI/Modal';

import { AuthServiceContext } from 'modules/authService/context';
import { TooltipTypes } from 'modules/globalTooltip';

import { Languages, availableLng } from 'lib/i18n';

import { LangToggleType } from './enum';
import { useLanguageChange } from './hooks';
import * as ScLangToggle from './styled';

const languageList = {
  [Languages.ENGLISH]: { name: 'English' },
  [Languages.RUSSIAN]: { name: 'Русский' },
};

interface LangToggleProps {
  type?: LangToggleType;
}

const LangToggle: React.FC<LangToggleProps> = ({
  type = LangToggleType.Desktop,
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { isAuthenticated } = useContext(AuthServiceContext);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideClickVisibility(false);
  const [changeLang] = useLanguageChange();
  const [userEditRequest] = useApi(editUser);

  const toggleVisibility = useCallback(() => {
    setIsComponentVisible((prev) => !prev);
  }, [setIsComponentVisible]);

  const handleLanguageChange = useCallback(
    async (lng: string) => {
      await changeLang(lng);
      if (isAuthenticated) {
        const { error } = await userEditRequest({ langCode: lng }).request;
        if (error) {
          showTooltip({
            type: TooltipTypes.ERROR,
            body: translationFactory('common:error.unknown'),
          });
        }
      }
    },
    [changeLang, isAuthenticated, userEditRequest]
  );

  if (availableLng.length <= 1) return null;

  return (
    <ScLangToggle.Container
      ref={ref}
      onClick={toggleVisibility}
      className={cx({ hideOnMobile: type === LangToggleType.Desktop })}
    >
      <ScLangToggle.SelectedLang className={cx({ open: isComponentVisible })}>
        <span>{language.toUpperCase()}</span>
        <Icon name="Arrow" />
      </ScLangToggle.SelectedLang>

      {type === LangToggleType.Desktop && (
        <ScLangToggle.ListOfLang
          className={cx({
            open: isComponentVisible,
            close: !isComponentVisible,
          })}
        >
          {isComponentVisible &&
            availableLng.map((lng) => (
              <li
                key={lng}
                onClick={() => handleLanguageChange(lng)}
                className={cx({ selected: lng === language })}
              >
                <p>{languageList[lng].name}</p>
                {lng === language && (
                  <Icon name="CheckmarkSelectedIcon" size="small" />
                )}
              </li>
            ))}
        </ScLangToggle.ListOfLang>
      )}

      {type === LangToggleType.Mobile && (
        <Fragment>
          <ModalSheet
            height={176}
            isOpen={isComponentVisible}
            onClose={toggleVisibility}
          >
            <ScLangToggle.MobileListOfLang>
              {availableLng.map((lng) => (
                <li
                  key={lng}
                  onClick={() => {
                    handleLanguageChange(lng);
                    toggleVisibility();
                  }}
                  className={cx({ selected: lng === language })}
                >
                  <p>{languageList[lng].name}</p>
                  {lng === language && (
                    <Icon name="CheckmarkSelectedIcon" size="medium" />
                  )}
                </li>
              ))}
            </ScLangToggle.MobileListOfLang>
          </ModalSheet>
        </Fragment>
      )}
    </ScLangToggle.Container>
  );
};

export default LangToggle;
