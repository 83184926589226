import { formatTypographyToFont } from 'helpers/typography';

import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  width: max-content;
  &.hideOnMobile {
    @media (max-width: ${({ theme }) => theme.tabletSize}) {
      display: none;
    }
  }
`;

export const SelectedLang = styled.div`
  display: flex;
  height: 28px;
  position: relative;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  padding: 4px 8px 4px 12px;
  border-radius: ${({ theme }) => theme.radius.field};
  background: ${({ theme }) => theme.colors.layer.layer01};
  border: 1px solid ${({ theme }) => theme.colors.border.field};
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.hover};
  }
  & > svg:last-child {
    transition-duration: 0.3s;
    path {
      fill: ${({ theme }) => theme.colors.icons.primary};
    }
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      width: 16px;
      height: auto;
    }
  }
  &.open {
    border-color: ${({ theme }) => theme.colors.border.focus};
    & > svg:last-child {
      transform: rotate(-180deg);
    }
  }
  & > span {
    font: ${({ theme }) =>
      formatTypographyToFont(theme.typography['label-medium'])};
    color: ${({ theme }) => theme.colors.text.primary};
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    height: 32px;
    padding: 8px;
    font-size: 12px;
  }
`;

export const ListOfLang = styled.ul`
  position: absolute;
  bottom: calc(100% + 4px);
  right: 0;
  transform: translateX(25%);
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 2;
  background: ${({ theme }) => theme.colors.background.foreground};
  border: 1px solid transparent;
  box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
  border-radius: 10px;
  padding: 4px 0;
  min-width: 140px;
  transition: opacity 0.3s;
  &.close {
    opacity: 0;
    pointer-events: none;
  }
  &.open {
    opacity: 1;
    pointer-events: auto;
  }
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    display: none;
  }
  & > li {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    padding: 4px 12px;
    font-size: 16px;
    line-height: normal;
    cursor: pointer;
    transition-duration: 0.3s;
    color: ${({ theme }) => theme.colors.text.primary};
    &:hover, &.selected {
      background: ${({ theme }) => theme.colors.layer.layerSelected01};
    }
    & > p {
      display: inline-flex;
      flex-direction: row;
      gap: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.text.primary};
    }
    & > svg:nth-last-child(1) path {
      fill: ${({ theme }) => theme.colors.icons.primary};
    }
`;

export const MobileListOfLang = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 12px 12px;
  & > li {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;
    padding: 10px 12px;
    gap: 8px;
    font-size: 16px;
    line-height: normal;
    cursor: pointer;
    transition-duration: 0.3s;
    color: ${({ theme }) => theme.colors.text.primary};
    &:hover, &.selected {
      background: ${({ theme }) => theme.colors.layer.layerSelected01};
    }
    & > p {
      display: inline-flex;
      flex-direction: row;
      gap: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.text.primary};
    }
    & > svg:nth-last-child(1) path {
      fill: ${({ theme }) => theme.colors.icons.primary};
    }
`;

export const HolderBtn = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 8px;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  & > button {
    background: #575c69;
    border: none;
    width: 113px;
    height: 4px;
    border-radius: 4px;
    pointer-events: none;
  }
`;

export const MobileOverlay = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(27, 28, 33, 0.8);
  z-index: 1;
  transition-duration: 0.3s;
  @media (min-width: ${({ theme }) => theme.tabletSize}) {
    display: none;
  }
  &.close {
    opacity: 0;
    pointer-events: none;
  }
  &.open {
    opacity: 1;
    pointer-events: auto;
  }
`;
