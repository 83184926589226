import { a } from 'react-spring';

import styled from 'styled-components/macro';

export const Background = styled(a.div)`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(27, 28, 33, 0.8);
  z-index: 100;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  pointer-events: none;
  &.open {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const HolderButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border: none;
  width: 100%;
  height: 24px;
  background: transparent;
  outline: none;
  position: absolute;
  top: 0;
  padding-top: 4%;
  ::after {
    content: '';
    position: absolute;
    top: 8px;
    background: ${({ theme }) => theme.colors.border.separator};
    border-radius: 4px;
    height: 6px;
    width: 64px;
    transition: 0.2s ease-in-out all;
  }
  :active {
    ::after {
      background: ${({ theme }) => theme.colors.icons.primary};

    }
    }
  }
`;

export const Container = styled(a.div)`
  display: flex;
  flex-direction: column;
  z-index: 101;
  position: fixed;
  left: -1px;
  padding-top: 24px;
  height: calc(100dvh + 100px);
  width: calc(100dvw + 2px);
  border: 1px solid ${({ theme }) => theme.colors.border.separator};
  border-radius: 24px 24px 0 0;
  background: ${({ theme }) => theme.colors.background.foreground};
  box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
  touch-action: none;
  transition: 0.05s ease-in-out opacity;
`;

export const ScrollableContent = styled.div`
  overflow: auto;
  height: 100%;
`;
