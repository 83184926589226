import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useTheme } from 'hooks/theme';

import { Button, ButtonLink } from 'components/UI/Button';
import { Icon } from 'components/UI/Icon';

import { PopupNotificationProps } from './type';

export const CryptoPopup: FC<PopupNotificationProps> = ({ closePopup }) => {
  const { t } = useTranslation('common');
  const { images } = useTheme();
  return (
    <>
      <div className="popup_image">
        <images.notificationCrypto.component />
      </div>
      <div className="popup_content">
        <h3>{t('common:notifications.crypto.title')}</h3>
        <div className="powered-by">
          Powered by <Icon name="CrystalIcon" />
        </div>
        <p>
          {t('common:notifications.crypto.content1')}
          <Icon name="DiamondIcon" className="crystal-icon" />{' '}
          {t('common:notifications.crypto.content2')}
        </p>
        <div className="popup_ctrl">
          <ButtonLink href="/crypto-check" onClick={closePopup}>
            {t('common:notifications.buttons.more')}
          </ButtonLink>
          <Button onClick={closePopup}>
            {t('common:notifications.buttons.informed')}
          </Button>
        </div>
      </div>
    </>
  );
};
