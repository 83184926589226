import React, { FC, useCallback, useEffect } from 'react';

import cx from 'classnames';
import { config, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';

import useAnimationTransition from 'hooks/animationTransition';

import { Portal } from 'components/portal';

import * as Styled from './ModalSheet.styled';
import { HolderButton } from './ModalSheet.styled';
import { ModalProps } from './types';

const ModalSheet: FC<ModalProps & { height?: number }> = ({
  children,
  isOpen,
  onClose,
  height = (window.visualViewport?.height || window.innerHeight) * 0.8,
}) => {
  const { transitionState, isAnimationActive } = useAnimationTransition(
    300,
    isOpen
  );

  const [{ y }, set] = useSpring(() => ({ y: height }));

  const open = useCallback(
    ({ canceled }: { canceled: boolean }) => {
      set({
        y: 0,
        immediate: false,
        config: canceled ? config.wobbly : config.stiff,
      });
    },
    [set]
  );

  const bind = useDrag(
    ({ last, vxvy: [, vy], movement: [, my], cancel, canceled }) => {
      if (my < -80) cancel();

      if (last) {
        if (my > height / 2 || vy > 0.5) {
          set({ y: height, config: { ...config.stiff, velocity: vy } });
          onClose?.();
        } else {
          open({ canceled });
        }
      } else {
        set({ y: my, immediate: true });
      }
    },
    {
      initial: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  useEffect(() => {
    if (isOpen) {
      open({ canceled: true });
    } else {
      set({
        y: height,
        immediate: false,
        config: { ...config.stiff, velocity: 0 },
      });
    }
  }, [height, isOpen, open, set]);

  if (!transitionState && !isAnimationActive) return null;

  return (
    <Portal>
      <Styled.Container
        style={{ bottom: `calc(-100dvh + ${height - 100}px)`, y }}
      >
        <HolderButton {...bind()} />
        <Styled.ScrollableContent
          style={{ paddingBottom: `calc(+100dvh - ${height - 124}px)` }}
        >
          {children}
        </Styled.ScrollableContent>
      </Styled.Container>

      <Styled.Background onClick={onClose} className={cx(isOpen && 'open')} />
    </Portal>
  );
};

export default ModalSheet;
