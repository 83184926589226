import React, { FC } from 'react';

import classNames from 'classnames';

import { Icon } from 'components/UI/Icon';

import * as Styled from './styled';
import { ButtonProps, ButtonSize, ButtonType } from './types';

export const Button: FC<ButtonProps> = ({
  children,
  loading = false,
  btnType = ButtonType.PRIMARY,
  btnSize = ButtonSize.MEDIUM,
  ...props
}) => {
  return (
    <Styled.ScButton
      {...props}
      className={classNames(`${props.className} ${btnSize} ${btnType}`, {
        loading: loading,
      })}
    >
      {loading && (
        <Styled.LoadingIconSpan>
          <Icon name="Loader" size="large" />
        </Styled.LoadingIconSpan>
      )}
      <Styled.ChildrensSpan>{children}</Styled.ChildrensSpan>
    </Styled.ScButton>
  );
};

export default Button;
