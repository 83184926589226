import * as React from 'react';
import { SVGProps } from 'react';

const SvgEyeHiddenIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="#FFF"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.882 19.797A10.95 10.95 0 0 1 12 21.5c-5.392 0-9.878-3.88-10.82-9a10.982 10.982 0 0 1 3.34-6.066L1.393 3.308l1.415-1.415 19.799 19.8-1.415 1.414-3.31-3.31ZM5.935 7.85a8.965 8.965 0 0 0-2.712 4.65 9.006 9.006 0 0 0 13.2 5.838l-2.027-2.028a4.5 4.5 0 0 1-6.206-6.206L5.935 7.85Zm6.979 6.978-3.242-3.242a2.5 2.5 0 0 0 3.24 3.241l.002.001Zm7.893 2.264-1.431-1.43a8.934 8.934 0 0 0 1.4-3.162A9.006 9.006 0 0 0 9.553 5.838L7.974 4.26C9.22 3.77 10.58 3.5 12 3.5c5.392 0 9.878 3.88 10.819 9a10.947 10.947 0 0 1-2.012 4.592Zm-9.084-9.084a4.5 4.5 0 0 1 4.769 4.77l-4.77-4.77Z" />
    </svg>
  );
};
export default SvgEyeHiddenIcon;
