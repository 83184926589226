import * as React from 'react';
import { SVGProps } from 'react';

const SvgExchangeWalletIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#E8EAED"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.47 19.413c.313.391.69.587 1.13.587h12.8c.44 0 .817-.196 1.13-.587.313-.392.47-.863.47-1.413v-5.2l2.6 2.6L24 14l-5-5-5 5 1.4 1.4 2.6-2.6V18H6v-1H4v1c0 .55.157 1.02.47 1.413ZM20 6v1h-2V6H6v5.6L8.6 9l1.4 1.4-5 5-5-5L1.4 9 4 11.6V6c0-.55.157-1.02.47-1.412C4.783 4.196 5.16 4 5.6 4h12.8c.44 0 .817.196 1.13.588.313.391.47.862.47 1.412Z" />
    </svg>
  );
};
export default SvgExchangeWalletIcon;
