import styled from 'styled-components/macro';

export const Frame = styled.div`
  position: fixed;
  height: calc(100dvh - 62px);
  inset: 62px 0 0;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 16px 16px 0;
  gap: 4px;
  background: ${({ theme }) => theme.colors.layer.layer01};
  z-index: 99;
  transform: translateY(100%);
  opacity: 0.2;
  transition: 0.25s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: none;
  &.open {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0);
  }
`;

export const CloseBtn = styled.button`
  cursor: pointer;
  display: flex;
  align-self: flex-end;
  background: none;
  border: none;
  padding: 0;
`;

export const ScrollableContent = styled.div`
  overflow: hidden auto;
`;
