import { formatTypographyToFont } from 'helpers/typography';

import styled from 'styled-components/macro';

import { ButtonSize, ButtonType } from './types';

export const LoadingIconSpan = styled.span`
  width: 28px;
  height: 28px;
  position: absolute;
`;

export const ChildrensSpan = styled.span``;

export const ScButton = styled.button`
  outline: none;
  cursor: pointer;
  position: relative;
  display: flex;
  gap: ${({ theme }) => theme.spacing['s-08']};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  max-height: 56px;
  transition: all ease 0.3s;
  &.loading {
    cursor: default;
    pointer-events: none;
    background: ${({ theme }) => theme.colors.button.fill.primaryHover};
    & > ${ChildrensSpan} {
      opacity: 0;
    }
  }
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing['s-08']};
    color: ${({ theme }) => theme.colors.button.text.primary};
    & > hr {
      width: 1px;
      height: 28px;
      background-color: #886c1e;
      border: none;
      margin: 0 ${({ theme }) => theme.spacing['s-04']};
    }
  }

  &.${ButtonSize.MINI} {
    height: 32px;
    padding: ${({ theme }) => theme.spacing['s-06']};
    font: ${({ theme }) =>
      formatTypographyToFont(theme.typography['label-small'])};
  }
  &.${ButtonSize.SMALL} {
    height: 36px;
    padding: ${({ theme }) => theme.spacing['s-08']}
      ${({ theme }) => theme.spacing['s-12']};
    font: ${({ theme }) =>
      formatTypographyToFont(theme.typography['label-small'])};
  }
  &.${ButtonSize.MEDIUM} {
    padding: ${({ theme }) =>
      theme.spacing['btn-y'] + ' ' + theme.spacing['btn-x']};
    font: ${({ theme }) =>
      formatTypographyToFont(theme.typography['label-medium'])};
  }
  &.${ButtonSize.LARGE} {
    padding: ${({ theme }) => theme.spacing['s-16']}
      ${({ theme }) => theme.spacing['btn-x']};
    font: ${({ theme }) =>
      formatTypographyToFont(theme.typography['label-medium'])};
  }
  &.${ButtonType.LINK} {
    border: none;
    padding: 0;
    border-radius: 0;
    background: transparent;
    & > span {
      color: ${({ theme }) => theme.colors.button.text.tertiaryLabel02};
    }
    &.loading {
      background: none;
    }
  }
  &.${ButtonType.PRIMARY} {
    border: none;
    border-radius: ${({ theme }) => theme.radius.mainSubtle};
    background: ${({ theme }) => theme.colors.button.fill.primaryBg};
    & > span {
      color: ${({ theme }) => theme.colors.button.text.primaryLabel};
    }
    &.loading,
    &:hover {
      background: ${({ theme }) => theme.colors.button.fill.primaryHover};
    }
    &:active {
      background: ${({ theme }) => theme.colors.button.fill.primaryPressed};
    }
  }
  &.${ButtonType.PRIMARY_GRADIENT} {
    border: none;
    border-radius: ${({ theme }) => theme.radius.mainSubtle};
    background: ${({ theme }) => theme.colors.button.fill.gradient};
    & > span {
      color: ${({ theme }) => theme.colors.text.inverse};
      font: ${({ theme }) =>
        formatTypographyToFont(theme.typography['label-large'])};
    }
  }
  &.${ButtonType.SECONDARY} {
    background: ${({ theme }) => theme.colors.button.fill.secondaryBg};
    border-radius: ${({ theme }) => theme.radius.mainSubtle};
    border: 1px solid transparent;
    & > span {
      color: ${({ theme }) => theme.colors.button.text.secondaryLabel};
    }
    &.loading {
      background: ${({ theme }) => theme.colors.button.fill.secondaryHover};
    }
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.button.border.secondary};
    }
    &:active,
    &:focus-within {
      background: ${({ theme }) => theme.colors.button.fill.secondaryPressed};
    }
  }
  &.${ButtonType.OUTLINE} {
    border: ${({ theme }) =>
      `1px solid ${theme.colors.button.border.border01}`};
    border-radius: ${({ theme }) => theme.radius.mainSubtle};
    background: transparent;
    & > span {
      color: ${({ theme }) => theme.colors.button.text.tertiaryLabel01};
    }
    &:hover {
      background: ${({ theme }) => theme.colors.button.fill.tertiaryHover01};
    }
    &:active,
    &:focus-within {
      background: ${({ theme }) => theme.colors.button.fill.tertiaryPressed01};
    }
    &:disabled {
      border-color: ${({ theme }) =>
        theme.colors.button.border.textButtonDisable};
      background: transparent;
      & > span {
        color: ${({ theme }) => theme.colors.button.text.textButtonDisable};
      }
    }
  }

  &.${ButtonType.OUTLINE_SECONDARY} {
    border: ${({ theme }) =>
      `1px solid ${theme.colors.button.border.border02}`};
    border-radius: ${({ theme }) => theme.radius.mainSubtle};
    background: transparent;
    & > span {
      color: ${({ theme }) => theme.colors.button.text.tertiaryLabel02};
    }
    &:hover {
      background: ${({ theme }) => theme.colors.button.fill.tertiaryHover02};
    }
    &:active,
    &:focus-within {
      background: ${({ theme }) => theme.colors.button.fill.tertiaryPressed02};
    }
    &:disabled {
      border-color: ${({ theme }) =>
        theme.colors.button.border.textButtonDisable};
      background: transparent;
      & > span {
        color: ${({ theme }) => theme.colors.button.text.textButtonDisable};
      }
    }
  }

  &.${ButtonType.OUTLINE_INTERACTIVE} {
    border: ${({ theme }) => `1px solid ${theme.colors.border.interactive}`};
    border-radius: ${({ theme }) => theme.radius.mainSubtle};
    background: transparent;
    & > span {
      color: ${({ theme }) => theme.colors.button.text.tertiaryLabel02};
    }
    &:hover {
      background: ${({ theme }) => theme.colors.button.fill.tertiaryHover02};
    }
    &:active,
    &:focus-within {
      background: ${({ theme }) => theme.colors.button.fill.tertiaryPressed02};
    }
    &:disabled {
      border-color: ${({ theme }) =>
        theme.colors.button.border.textButtonDisable};
      background: transparent;
      & > span {
        color: ${({ theme }) => theme.colors.button.text.textButtonDisable};
      }
    }
  }

  &.${ButtonType.WARNING} {
    border: ${({ theme }) =>
      `1px solid ${theme.colors.button.border.border03}`};
    border-radius: ${({ theme }) => theme.radius.mainSubtle};
    background: transparent;
    & > span {
      color: ${({ theme }) => theme.colors.button.text.tertiaryLabel03};
    }
    &:hover {
      background: ${({ theme }) => theme.colors.button.fill.tertiaryHover03};
    }
    &:active,
    &:focus-within {
      background: ${({ theme }) => theme.colors.button.fill.tertiaryPressed03};
    }
    &:disabled {
      border-color: ${({ theme }) =>
        theme.colors.button.border.textButtonDisable};
      background: transparent;
      & > span {
        color: ${({ theme }) => theme.colors.button.text.textButtonDisable};
      }
    }
  }

  &.${ButtonType.ICON} {
    padding: 2px;
    background: none;
    border: none;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    background: ${({ theme }) => theme.colors.button.fill.disabled};
    border-color: transparent;
    span {
      color: ${({ theme }) => theme.colors.button.text.textButtonDisable};
      & > hr {
        background-color: ${({ theme }) =>
          theme.colors.button.text.textButtonDisable};
      }
    }
  }
`;
