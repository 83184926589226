import { ButtonHTMLAttributes } from 'react';

export enum ButtonType {
  PRIMARY = 'primary',
  PRIMARY_GRADIENT = 'primary-gradient',
  SECONDARY = 'secondary',
  OUTLINE = 'outline',
  OUTLINE_SECONDARY = 'outline-secondary',
  OUTLINE_INTERACTIVE = 'outline-interactive',
  LINK = 'link',
  WARNING = 'warning',
  ICON = 'icon',
}

export enum ButtonSize {
  MINI = 'mini',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  btnType?: ButtonType;
  btnSize?: ButtonSize;
  asLink?: boolean | false;
  loading?: boolean;
}
