import { ENV } from 'env/env.config';

import { ThemeDesignConfig } from 'themes/contracts';

const widgetScript = (
  key: string,
  colors: ThemeDesignConfig['colors'],
  language: string
) => `
    (function(d, w, c) {
    w.ChatraSetup = {
    language: '${language}',
    zIndex: 10,
    colors: {
        buttonText: '${colors.button.text.primaryLabel}', // chat button text/icon color
        buttonBg: '${colors.button.fill.primaryBg}', // chat button background color
        clientBubbleBg: '${colors.text.success}', // visitor’s message bubble color
        agentBubbleBg: '${colors.text.success}' // agent’s message bubble color
    }
}
        w.ChatraID = '${key}';
        var s = d.createElement('script');
        w[c] = w[c] || function() {
            (w[c].q = w[c].q || []).push(arguments);
        };
        s.async = true;
        s.src = 'https://call.chatra.io/chatra.js';
        if (d.head) d.head.appendChild(s);
    })(document, window, 'Chatra');
`;

export const chatraWidget = {
  key: ENV.CHATRA_ID,
  add: function (colors: ThemeDesignConfig['colors'], language: string) {
    const script = document.createElement('script');
    script.id = `script_${this.key}`;
    script.async = true;

    if (!this.key) return;

    script.innerHTML = widgetScript(this.key, colors, language);
    document.head.appendChild(script);
  },
  remove: function () {
    const elements = [document.getElementById(`script_${this.key}`)];

    elements.forEach((element) => {
      if (element != null) {
        element.remove();
      }
    });
  },
};
