import styled from 'styled-components/macro';

const ScLoadingProgressBar = styled.div`
  pointer-events: none;
  .bar {
    background: ${({ theme }) => theme.colors.button.fill.primaryBg};
    position: fixed;
    z-index: 98;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    @media (max-width: ${({ theme }) => theme.tabletSize}) {
      top: auto;
      bottom: 52px;
      height: 2px;
    }
  }

  .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow:
      0 0 10px ${({ theme }) => theme.colors.button.fill.primaryBg},
      0 0 5px ${({ theme }) => theme.colors.button.fill.primaryBg};
    opacity: 1;

    transform: rotate(3deg) translate(0px, -4px);
    @media (max-width: ${({ theme }) => theme.tabletSize}) {
      display: none;
    }
  }
`;

export default ScLoadingProgressBar;
