import * as React from 'react';
import { SVGProps } from 'react';

const SvgArrowExchangeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#0F172A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16 16v-4l5 5-5 5v-4H4v-2h12ZM8 2v3.999L20 6v2H8v4L3 7l5-5Z" />
    </svg>
  );
};
export default SvgArrowExchangeIcon;
