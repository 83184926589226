import * as React from 'react';
import { SVGProps } from 'react';

const SvgDepositIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.859 2.333v2.353a9.357 9.357 0 0 0 1.17 18.641 9.357 9.357 0 0 0 9.283-8.187h2.355c-.588 5.91-5.574 10.527-11.638 10.527-6.46 0-11.696-5.237-11.696-11.696 0-6.065 4.616-11.052 10.526-11.638Zm10.474 0-7.824 7.825-3.842-3.842v9.334H21l-3.842-3.842 7.825-7.825-1.65-1.65Z"
        fill="#F8FAFC"
      />
    </svg>
  );
};
export default SvgDepositIcon;
