import React, { FC, ReactNode, useCallback, useEffect } from 'react';

import cx from 'classnames';

import useAnimationTransition from 'hooks/animationTransition';

import { bodyScrollLockToggle } from 'helpers/common';

import { Portal } from 'components/portal';

import * as ScSidebar from './styled';

interface SideBarProps {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  hideBackground?: boolean;
}

const SideBar: FC<SideBarProps> = ({
  isOpen,
  onClose,
  hideBackground = false,
  children,
}) => {
  const { transitionState, isAnimationActive } = useAnimationTransition(
    2500,
    isOpen
  );

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    bodyScrollLockToggle(isOpen);
  }, [isOpen]);

  if (!transitionState && !isAnimationActive) return null;

  return (
    <Portal>
      <ScSidebar.Content
        className={cx('', {
          active: isAnimationActive,
          'border-l': hideBackground,
        })}
      >
        {children}
      </ScSidebar.Content>
      {!hideBackground && (
        <ScSidebar.Background
          onClick={handleClose}
          className={cx('', isAnimationActive && 'active')}
        />
      )}
    </Portal>
  );
};

export default SideBar;
