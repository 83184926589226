import { VerifyStatus } from 'helpers/constants';
import { formatTypographyToFont } from 'helpers/typography';

import { ScButton } from 'components/UI/Button';
import { dropDownHide, dropDownShow } from 'components/globalStyles/animation';

import styled from 'styled-components/macro';

export const Container = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing['s-12']};
`;

export const Mail = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing['s-04']};
  justify-content: center;
  align-items: flex-end;
  p {
    font: ${({ theme }) =>
      formatTypographyToFont(theme.typography['label-medium'])};
    color: ${({ theme }) => theme.colors.text.primary};
  }
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    display: none;
  }
`;

export const DropdownTrigger = styled.div`
  position: relative;
  z-index: 1;
  & > div:nth-child(1) {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing['s-04']};
    svg {
      rotate: 0deg;
      transition: 0.3s ease-in-out;
    }
  }
  &.active {
    & > div:nth-child(1) > svg {
      rotate: 180deg;
    }
  }
`;

export const Arrow = styled.div`
  display: flex;
  rotate: 0deg;
  transition: 0.3s ease-in-out;
  &.rotate {
    rotate: 180deg;
  }
`;

export const Dropdown = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  padding: ${({ theme }) => theme.spacing['s-16']}
    ${({ theme }) => theme.spacing['s-12']}
    ${({ theme }) => theme.spacing['s-12']};
  border-radius: ${({ theme }) => theme.radius.mainStrong};
  background: ${({ theme }) => theme.colors.layer.layer01};
  border: 1px solid ${({ theme }) => theme.colors.border.system};
  box-shadow: 0 0 8px 4px #1c1e2920;
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  z-index: 16;
  min-width: 280px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  &.open {
    animation: ${dropDownShow} linear 0.2s forwards;
  }
  animation: ${dropDownHide} linear 0.2s forwards;
  & > hr {
    margin: 0;
    width: 100%;
    border-color: ${({ theme }) => theme.colors.border.separator};
    border-top: none;
  }
  & > .dropdown__list {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: stretch;
    gap: 4px;
  }
`;

export const DropdownItem = styled.div`
  cursor: pointer;
  display: flex;
  align-self: stretch;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radius.mainSubtle};
  @media (min-width: ${({ theme }) => theme.tabletSize}) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.layer.layerHover01};
    }
  }
  & > .dropdownItem__logOut {
    display: inline-flex;
    align-items: center;
    min-height: 36px;
    padding: 4px 16px;
    font: ${({ theme }) =>
      formatTypographyToFont(theme.typography['overline-medium'])};
    color: ${({ theme }) => theme.colors.text.primary};
  }
  & > a {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 4px 16px;
    font: ${({ theme }) =>
      formatTypographyToFont(theme.typography['overline-medium'])};
    color: ${({ theme }) => theme.colors.text.primary};
    white-space: nowrap;
    & > .dropdownItem__link {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
    svg {
      width: 24px;
      height: 24px;
      & *[class$='svg-fill'] {
        fill: ${({ theme }) => theme.colors.icons.primary};
      }
    }
    @media (max-width: ${({ theme }) => theme.tabletSize}) {
      font: ${({ theme }) =>
        formatTypographyToFont(theme.typography['paragraph-large'])};
    }
  }
`;

export const SideBarContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 76px 0 48px;
  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  & > ${ScButton} {
    margin: 0 16px;
  }
`;

export const ScUserProfileBtn = styled.button`
  padding: 0;
  background: none;
  border: none;
  position: absolute;
  right: 16px;
  top: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  gap: 4px;
  svg {
    rotate: 0deg;
    transition: 0.3s ease-in-out;
  }
  &.active > svg {
    rotate: 180deg;
  }
`;

export const ProfileVerifyStatus = styled.div<{ status: VerifyStatus }>`
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.profileSoonTagBg};
  ${({ status, theme }) => {
    switch (status) {
      case VerifyStatus.NOT_VERIFIED:
        return `background: ${theme.colors.layer.danger}; color: ${theme.colors.text.danger}`;
      case VerifyStatus.VERIFYING:
        return `background: ${theme.colors.layer.info}; color: ${theme.colors.text.info}`;
      case VerifyStatus.LEVEL_2:
        return `background: ${theme.colors.layer.warning}; color: ${theme.colors.text.warning}`;
      case VerifyStatus.VERIFIED:
        return `background: ${theme.colors.layer.success}; color: ${theme.colors.text.success}`;
    }
  }}
`;
