import * as React from 'react';
import { SVGProps } from 'react';

const SvgWithdrawWalletIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#E8EAED"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.587 19.413C2.98 19.803 3.45 20 4 20h16c.55 0 1.02-.196 1.413-.587.391-.392.587-.863.587-1.413V6c0-.55-.196-1.02-.587-1.412A1.926 1.926 0 0 0 20 4h-5l2 2h3v12H4V6h3l2-2H4c-.55 0-1.02.196-1.413.588A1.926 1.926 0 0 0 2 6v12c0 .55.196 1.02.587 1.413Z" />
      <path d="m7 9 5-5 5 5-1.4 1.4L13 7.8v7.6h-2V7.8l-2.6 2.6L7 9Z" />
    </svg>
  );
};
export default SvgWithdrawWalletIcon;
