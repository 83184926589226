import { Link } from 'react-navi';

import { formatTypographyToFont } from 'helpers/typography';

import { ScLangToggle } from 'components/LangToggle';
import {
  hide,
  show,
  sidebarLeftClose,
  sidebarLeftShow,
} from 'components/globalStyles/animation';

import styled from 'styled-components/macro';

export const ScMobileMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 30%;
  max-width: 280px;
  background: ${({ theme }) => theme.colors.background.foreground};
  padding: 48px 16px 12px 16px;
  border-radius: 0;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
  animation: ${sidebarLeftClose} 0.3s ease forwards;
  opacity: 0;
  pointer-events: none;
  &.open {
    pointer-events: auto;
    animation: ${sidebarLeftShow} 0.3s ease forwards;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    gap: 32px;
    & > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      gap: 20px;
      a {
        display: inline-flex;
        gap: 4px;
        font: ${({ theme }) =>
          formatTypographyToFont(theme.typography['overline-medium'])};
      }
      a.nav__link {
        color: ${({ theme }) => theme.colors.text.primary};
      }
    }
    & > div:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      span {
        color: ${({ theme }) => theme.colors.text.primary};
        font: ${({ theme }) =>
          formatTypographyToFont(theme.typography['paragraph-medium'])};
      }
    }
  }

  .menu {
    margin: 28px 0 48px 0;
    & > li {
      display: flex;
      flex-direction: row;
      gap: 12px;
      .defaultIcon {
        width: 7px;
        height: 7px;
        background-color: ${({ theme }) => theme.colors.layer.layer01};
        border-radius: 50%;
        display: inline-block;
        margin-right: 21px;
      }
      a {
        padding: 14px 0;
        span {
          font: ${({ theme }) =>
            formatTypographyToFont(theme.typography['paragraph-large'])};
        }
        &.active {
          span {
            color: ${({ theme }) => theme.colors.text.brand};
          }
          svg,
          path {
            fill: ${({ theme }) => theme.colors.button.icon.icon01};
            [class$='stroke-color'] {
              stroke: ${({ theme }) => theme.colors.text.brand};
            }
          }
        }
      }
      span {
        color: ${({ theme }) => theme.colors.text.primary};
        font: ${({ theme }) =>
          formatTypographyToFont(theme.typography['label-large'])};
      }
      svg,
      path {
        fill: ${({ theme }) => theme.colors.icons.primary};
      }
      [class$='stroke-color'] {
        stroke: ${({ theme }) => theme.colors.icons.primary};
      }
    }
  }

  ${ScLangToggle.SelectedLang} {
    background-color: ${({ theme }) => theme.colors.field.default};
  }
`;

export const ScMoreMenuBtn = styled.button`
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  display: none;
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    display: block;
  }
`;

export const ScMobileBg = styled.div`
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(27, 28, 33, 0.8);
    z-index: 99;
    animation: ${hide} ease-in-out 0.25s forwards;
    opacity: 0;
    pointer-events: none;
    &.open {
      pointer-events: auto;
      animation: ${show} ease-in-out 0.25s forwards;
      opacity: 1;
    }
  }
`;

export const ScCloseMenuBtn = styled.button`
  position: absolute;
  left: 12px;
  top: 14px;
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  display: none;
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    display: block;
  }
`;

export const ScNavLink = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  gap: 12px;
  @media (min-width: ${({ theme }) => theme.mobileSize}) {
    &:hover {
      span {
        color: ${({ theme }) => theme.colors.text.primary};
      }
      svg,
      path {
        fill: ${({ theme }) => theme.colors.icons.primary};
      }
      [class$='stroke-color'] {
        stroke: ${({ theme }) => theme.colors.icons.primary};
      }
    }
  }
  span {
    color: ${({ theme }) => theme.colors.text.primary};
    font: 400 14px / 16px ${({ theme }) => theme.mainFont};
  }
  svg {
    width: 24px;
    box-sizing: content-box;
    border-radius: 50%;
    }
    &,
    path {
      fill: ${({ theme }) => theme.colors.icons.primary};
    }
    [class$='stroke-color'] {
      stroke: ${({ theme }) => theme.colors.icons.primary};
      stroke-width: 0.5;
    }
    @media (max-width: ${({ theme }) => theme.tabletSize}) {
      &,
      path {
        fill: #fff;
        fill-opacity: 1;
      }
      [class$='stroke-color'] {
        stroke: #fff;
      }
    }
  }
  &.active {
    @media (min-width: ${({ theme }) => theme.mobileSize}) {
      span {
        color: ${({ theme }) => theme.colors.text.primary};
      }
       svg {
        &, path {
          fill: ${({ theme }) => theme.colors.icons.primary};
        }
        [class$='stroke-color'] {
          stroke: ${({ theme }) => theme.colors.icons.primary};
        }
      }
    }
  }
`;
