import * as React from 'react';
import { SVGProps } from 'react';

const SvgWalletSoonIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={22}
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="fill-color"
        d="M22.333 7.833h.834v8.334h-.834v2.5a.833.833 0 0 1-.833.833h-15a.833.833 0 0 1-.833-.833V5.333A.833.833 0 0 1 6.5 4.5h15a.833.833 0 0 1 .833.833v2.5Zm-1.666 8.334h-5a4.167 4.167 0 1 1 0-8.334h5V6.167H7.332v11.666h13.333v-1.666ZM21.5 14.5v-5h-5.834a2.5 2.5 0 0 0 0 5H21.5Zm-5.834-3.333h2.5v1.666h-2.5v-1.666Z"
      />
      <g clipPath="url(#a)">
        <circle cx={8} cy={8.889} r={6.222} fill="#FF4E4E" />
        <path
          d="m15.937 7.766-.946-1.64V4.235a.469.469 0 0 0-.234-.406l-1.64-.946-.945-1.639a.469.469 0 0 0-.406-.234H9.873L8.234.063a.469.469 0 0 0-.468 0l-1.64.946H4.235a.469.469 0 0 0-.406.234l-.946 1.64-1.639.945a.469.469 0 0 0-.234.406v1.893L.063 7.766a.469.469 0 0 0 0 .468l.946 1.64v1.892c0 .167.09.322.234.406l1.64.946.945 1.639a.469.469 0 0 0 .406.234h1.893l1.639.946a.468.468 0 0 0 .468 0l1.64-.946h1.892c.167 0 .322-.09.406-.234l.946-1.64 1.639-.945a.468.468 0 0 0 .234-.406V9.873l.946-1.639a.469.469 0 0 0 0-.468Zm-10.23 1.74a.469.469 0 0 1-.858.26l-1.15-1.724v1.464a.469.469 0 0 1-.938 0V6.494a.469.469 0 0 1 .86-.26L4.77 7.958V6.494a.469.469 0 0 1 .937 0v3.012ZM7.75 7.531a.469.469 0 0 1 0 .938h-.535v.569H8.25a.469.469 0 0 1 0 .937H6.745a.469.469 0 0 1-.469-.469V6.494c0-.26.21-.469.469-.469H8.25a.469.469 0 0 1 0 .938H7.214v.568h.535Zm5.727-.924-.754 3.013a.469.469 0 0 1-.899.035l-.56-1.679-.559 1.679a.47.47 0 0 1-.9-.035l-.753-3.013a.469.469 0 1 1 .91-.227l.363 1.45.495-1.484a.469.469 0 0 1 .89 0l.494 1.485.363-1.45a.469.469 0 1 1 .91.226Z"
          fill="#FF4E4E"
        />
        <path
          d="M2.698 10.045c-.254 0-.497-.037-.73-.11a1.638 1.638 0 0 1-.55-.29l.275-.615c.07.053.156.102.26.145.103.043.215.078.335.105.12.027.235.04.345.04.16 0 .27-.025.33-.075.06-.05.09-.108.09-.175a.195.195 0 0 0-.075-.16.794.794 0 0 0-.23-.12l-.42-.155a2.078 2.078 0 0 1-.63-.35c-.157-.14-.235-.332-.235-.575 0-.283.11-.505.33-.665.223-.163.54-.245.95-.245.266 0 .496.033.69.1.196.063.356.148.48.255l-.255.585a1.384 1.384 0 0 0-.26-.11 2.01 2.01 0 0 0-.325-.075c-.11-.02-.215-.03-.315-.03a.622.622 0 0 0-.3.055c-.06.037-.09.085-.09.145 0 .05.023.097.07.14a.79.79 0 0 0 .235.12l.365.135c.336.123.573.255.71.395.14.137.21.313.21.53 0 .29-.109.53-.325.72-.214.187-.525.28-.935.28Zm3.274-.025a1.85 1.85 0 0 1-.865-.19 1.35 1.35 0 0 1-.555-.555 1.781 1.781 0 0 1-.195-.86c0-.333.065-.62.195-.86.13-.243.315-.43.555-.56a1.81 1.81 0 0 1 .865-.195c.334 0 .617.065.85.195.237.13.417.317.54.56.124.24.185.527.185.86 0 .5-.138.893-.415 1.18-.273.283-.66.425-1.16.425Zm0-.72c.227 0 .399-.073.515-.22.12-.15.18-.37.18-.66 0-.29-.06-.512-.18-.665-.116-.157-.288-.235-.515-.235a.672.672 0 0 0-.54.235c-.13.153-.195.375-.195.665 0 .29.065.51.195.66.134.147.314.22.54.22Zm3.638.72a1.85 1.85 0 0 1-.865-.19 1.35 1.35 0 0 1-.555-.555 1.781 1.781 0 0 1-.195-.86c0-.333.065-.62.195-.86.13-.243.315-.43.555-.56A1.81 1.81 0 0 1 9.61 6.8c.333 0 .617.065.85.195.237.13.417.317.54.56.123.24.185.527.185.86 0 .5-.138.893-.415 1.18-.273.283-.66.425-1.16.425Zm0-.72c.227 0 .398-.073.515-.22.12-.15.18-.37.18-.66 0-.29-.06-.512-.18-.665-.117-.157-.288-.235-.515-.235a.673.673 0 0 0-.54.235c-.13.153-.195.375-.195.665 0 .29.065.51.195.66.133.147.313.22.54.22Zm2.073.7V6.82h.945l1.055 1.96V6.82h.85V10h-.97l-1.03-1.885V10h-.85Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path className="fill-color" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgWalletSoonIcon;
