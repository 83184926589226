import React, { FC, useCallback, useEffect, useRef } from 'react';

import cx from 'classnames';

import useAnimationTransition from 'hooks/animationTransition';

import { bodyScrollLockToggle } from 'helpers/common';

import { Icon } from 'components/UI/Icon';
import { Portal } from 'components/portal';

import StyledModal from './Modal.styled';
import { ModalProps } from './types';

const Modal: FC<ModalProps> = ({ children, isOpen, onClose }) => {
  const { transitionState, isAnimationActive } = useAnimationTransition(
    300,
    isOpen
  );
  const contentRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [children]);

  useEffect(() => {
    bodyScrollLockToggle(isOpen);
  }, [isOpen]);

  if (!isOpen && !transitionState && !isAnimationActive) return null;

  return (
    <Portal>
      <StyledModal
        className={cx('', {
          open: isAnimationActive,
        })}
      >
        <StyledModal.Background
          className={cx('', {
            open: isAnimationActive,
          })}
          onClick={handleClose}
        />

        <StyledModal.Content
          className={cx('', {
            open: isAnimationActive,
          })}
        >
          <StyledModal.CloseBtn onClick={handleClose}>
            <Icon name="CloseIcon" />
          </StyledModal.CloseBtn>

          <StyledModal.ScrollableContent ref={contentRef}>
            {children}
          </StyledModal.ScrollableContent>
        </StyledModal.Content>
      </StyledModal>
    </Portal>
  );
};

export default Modal;
