import React, { AnchorHTMLAttributes, FC } from 'react';

import classNames from 'classnames';
import { Link } from 'react-navi';

import { Icon } from 'components/UI/Icon';

import { LinkProps } from 'react-navi/dist/types/Link';

import * as Styled from './styled';
import { ButtonProps, ButtonSize, ButtonType } from './types';

export type ButtonLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  LinkProps &
  ButtonProps & { as?: any };

const ButtonLink: FC<ButtonLinkProps> = ({
  children,
  loading = false,
  btnType = ButtonType.PRIMARY,
  btnSize = ButtonSize.MEDIUM,
  ...props
}) => {
  return (
    <Styled.ScButton
      as={Link}
      {...props}
      className={classNames(`${props.className} ${btnSize} ${btnType}`, {
        loading: loading,
      })}
    >
      {loading && (
        <Styled.LoadingIconSpan>
          <Icon name="Loader" size="large" />
        </Styled.LoadingIconSpan>
      )}
      <span> {children}</span>
    </Styled.ScButton>
  );
};

export default ButtonLink;
