import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-navi';

import { useTheme } from 'hooks/theme';
import { useOutsideClickVisibility } from 'hooks/visibility';

import { copyWithTooltip } from 'helpers/copy';

import { Icon } from 'components/UI/Icon';

import { ENV } from 'env/env.config';

import { ScMoreMenuDropdown, ScNavMenu, ScNavbarLink } from './styled';

export const NavMenu = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <ScNavMenu>
      <ScNavbarLink as={Link} href="/" exact activeClassName="active">
        <Icon name="ExchangeIcon" fill={colors.icons.subtle} size="medium" />
        <span>{t(`common:navMenu.exchange`)}</span>
      </ScNavbarLink>

      <ScNavbarLink as={Link} href="/wallet" exact activeClassName="active">
        <Icon name="WalletSoonIcon" fill={colors.icons.subtle} size="medium" />
        <span>{t(`common:navMenu.wallet`)}</span>
      </ScNavbarLink>

      <ScNavbarLink as={Link} href="/crypto-check" activeClassName="active">
        <Icon name="CryptoCheckIcon" fill={colors.icons.subtle} size="medium" />
        <span>{t(`common:navMenu.cryptoCheck`)}</span>
      </ScNavbarLink>

      <ScNavbarLink as={Link} href="/api" activeClassName="active">
        <Icon name="ApiIcon" fill={colors.icons.subtle} size="medium" />
        <span>{t(`common:navMenu.api`)}</span>
      </ScNavbarLink>

      <MoreMenuDropdown />
    </ScNavMenu>
  );
};

const MoreMenuDropdown = () => {
  const { t } = useTranslation('common');
  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideClickVisibility(false);

  const toggle = useCallback(
    () => void setIsComponentVisible((i) => !i),
    [setIsComponentVisible]
  );

  const { isHideReserves, isHidePartners, isHideBonuses, colors } = useTheme();

  return (
    <ScMoreMenuDropdown
      ref={ref}
      className={isComponentVisible ? 'active' : ''}
    >
      <div className="dropdown-btn" onClick={toggle}>
        <Icon name="MoreMenuIcon" fill={colors.icons.subtle} size="medium" />
      </div>

      {isComponentVisible && (
        <div className="dropdown-menu">
          {!isHideReserves && (
            <Link
              href="/reserve"
              onClick={toggle}
              className="dropdown-menu__link"
            >
              <Icon
                name="CourseIcon"
                fill={colors.icons.subtle}
                size="medium"
              />

              <div>
                <p>{t(`navMenu.reserve`)}</p>
                <span>{t(`navMenu.reserveDesc`)}</span>
              </div>
            </Link>
          )}

          {!isHideBonuses && (
            <Link
              href="/bonus"
              onClick={toggle}
              className="dropdown-menu__link"
            >
              <Icon name="BonusIcon" fill={colors.icons.subtle} size="medium" />

              <div>
                <p>{t(`navMenu.bonus`)}</p>
                <span>{t(`navMenu.bonusDesc`)}</span>
              </div>
            </Link>
          )}

          {!isHidePartners && (
            <Link
              href="/partner"
              onClick={toggle}
              className="dropdown-menu__link"
            >
              <Icon name="HeartIcon" fill={colors.icons.subtle} size="medium" />

              <div>
                <p>{t(`navMenu.partner`)}</p>
                <span>{t(`navMenu.partnerDesc`)}</span>
              </div>
            </Link>
          )}

          <Link href="/faq" onClick={toggle} className="dropdown-menu__link">
            <Icon name="FaqIcon" fill={colors.icons.subtle} size="medium" />

            <div>
              <div>
                <p>{t(`navMenu.faq`)}</p>
                <span>{t(`navMenu.faqDesc`)}</span>
              </div>
            </div>
          </Link>

          <hr />

          <Link
            href="/terms-of-use"
            onClick={toggle}
            className="dropdown-menu__link addons"
          >
            {t(`navMenu.termsOfUse`)}
          </Link>

          <Link
            href="/privacy"
            onClick={toggle}
            className="dropdown-menu__link addons"
          >
            {t(`navMenu.privacy`)}
          </Link>

          <Link
            href="/policy"
            onClick={toggle}
            className="dropdown-menu__link addons"
          >
            {t(`navMenu.policy`)}
          </Link>

          {ENV.APP_SUPPORT_EMAIL && (
            <Link
              href={`mailto:${ENV.APP_SUPPORT_EMAIL}`}
              className="dropdown-menu__mail"
            >
              {ENV.APP_SUPPORT_EMAIL}

              <Icon
                name="CopyIcon"
                onClick={(e) => {
                  e.preventDefault();
                  copyWithTooltip(ENV.APP_SUPPORT_EMAIL || '');
                }}
                fill={colors.icons.primary}
                size="mini"
              />
            </Link>
          )}
        </div>
      )}
    </ScMoreMenuDropdown>
  );
};
