import React, { FC, Fragment, useCallback } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-navi';

import useAnimationTransition from 'hooks/animationTransition';
import { useTheme } from 'hooks/theme';
import { useOutsideClickVisibility } from 'hooks/visibility';

import { uuid } from 'helpers/base';
import { bodyScrollLockToggle } from 'helpers/common';
import { copyWithTooltip } from 'helpers/copy';
import { getLocaleBestchangeUrl } from 'helpers/i18n';

import { LangToggle, LangToggleType } from 'components/LangToggle';
import { Icon } from 'components/UI/Icon';
import { Portal } from 'components/portal';

import { ENV } from 'env/env.config';

import { Languages, availableLng } from 'lib/i18n';
import { LinkProps } from 'react-navi/dist/types/Link';
import { Image } from 'themes/contracts';

import {
  ScCloseMenuBtn,
  ScMobileBg,
  ScMobileMenu,
  ScMoreMenuBtn,
  ScNavLink,
} from './styled';

interface SidebarLinkProps extends LinkProps {
  as?: any;
}

export const NavLink: FC<SidebarLinkProps> = ({ ...props }) => (
  <ScNavLink {...props} activeClassName="active" />
);

export const MobileMenu = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { images, isHideBonuses, isHidePartners, isHideReserves, colors } =
    useTheme();

  const { isComponentVisible, setIsComponentVisible } =
    useOutsideClickVisibility(false);
  const { transitionState, isAnimationActive } = useAnimationTransition(
    300,
    isComponentVisible
  );

  const toggle = useCallback(() => {
    setIsComponentVisible((prev) => {
      bodyScrollLockToggle(!prev);
      return !prev;
    });
  }, [setIsComponentVisible]);

  function tNavMenu(key: string) {
    return t(`common:navMenu.${process.env.THEME}.${key}`, {
      defaultValue: t(`common:navMenu.${key}`),
    });
  }

  const items: {
    href: string;
    icon: Image;
    text: string;
    exact: boolean;
    as?: any;
  }[] = [
    ...(isHideBonuses
      ? []
      : [
          {
            href: '/bonus',
            icon: images.bonusIcon,
            text: 'bonus',
            exact: true,
            as: Link,
          },
        ]),
    ...(isHideReserves
      ? []
      : [
          {
            href: '/reserve',
            icon: images.courseIcon,
            text: 'reserve',
            exact: true,
            as: Link,
          },
        ]),
    ...(isHidePartners
      ? []
      : [
          {
            href: '/partner',
            icon: images.partnersIcon,
            text: 'partner',
            exact: true,
            as: Link,
          },
        ]),
    {
      href: '/faq',
      icon: images.faqIcon,
      text: 'faq',
      exact: true,
      as: Link,
    },
  ];

  return (
    <Fragment>
      <ScMoreMenuBtn onClick={toggle}>
        <Icon name="MoreMenuIcon" fill={colors.icons.primary} />
      </ScMoreMenuBtn>

      {(transitionState || isAnimationActive) && (
        <Portal>
          <ScMobileMenu className={cx(isAnimationActive && 'open')}>
            <ScCloseMenuBtn onClick={toggle}>
              <Icon
                name="CloseIcon"
                fill={colors.icons.primary}
                width={32}
                height={32}
              />
            </ScCloseMenuBtn>
            <div>
              <ul className="menu">
                {items.map(({ href, icon, text, exact, as }) => {
                  return (
                    <li key={uuid()}>
                      <NavLink
                        as={as}
                        href={href}
                        exact={exact}
                        onClick={toggle}
                      >
                        {icon ? (
                          <icon.component />
                        ) : (
                          <span className="defaultIcon" />
                        )}
                        <span>{tNavMenu(text)}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="footer-links">
              <div>
                <Link
                  href="/policy"
                  onClick={() => setIsComponentVisible(false)}
                  className="nav__link"
                >
                  {t('common:navMenu.policy')}
                </Link>

                <Link
                  href="/terms-of-use"
                  onClick={() => setIsComponentVisible(false)}
                  className="nav__link"
                >
                  {t(`navMenu.termsOfUse`)}
                </Link>

                <Link
                  href="/privacy"
                  onClick={() => setIsComponentVisible(false)}
                  className="nav__link"
                >
                  {t(`navMenu.privacy`)}
                </Link>

                {ENV.APP_SUPPORT_EMAIL && (
                  <Link
                    href={`mailto:${ENV.APP_SUPPORT_EMAIL}`}
                    className="dropdown-menu__mail"
                  >
                    {ENV.APP_SUPPORT_EMAIL}
                    <Icon
                      name="CopyIcon"
                      onClick={(e) => {
                        e.preventDefault();
                        copyWithTooltip(ENV.APP_SUPPORT_EMAIL || '');
                      }}
                      fill={colors.icons.primary}
                      size="mini"
                    />
                  </Link>
                )}

                {availableLng.length > 1 && (
                  <LangToggle type={LangToggleType.Mobile} />
                )}
              </div>
              <div>
                <span>
                  {t(`common:footer.${ENV.APP_THEME}.copyright`, {
                    value: ENV.APP_TITLE,
                    defaultValue: t('common:footer.copyright'),
                  })}
                </span>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={getLocaleBestchangeUrl(language as Languages)}
                >
                  <Icon width={77} name="BestchangeIcon" />
                </a>
              </div>
            </div>
          </ScMobileMenu>
          <ScMobileBg
            onClick={toggle}
            className={cx(isAnimationActive && 'open')}
          />
        </Portal>
      )}
    </Fragment>
  );
};
