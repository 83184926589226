import React, { FC, useEffect } from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from 'components/globalStyles';
import { ErrorBoundary } from 'components/internal/errorBoundary';
import { InternalErrorBlock } from 'components/internalErrorBlock';

import { AuthServiceProvider } from 'modules/authService/provider';

import { chatraWidget } from '3rdParty/chatraWidget';
import { SeverityLevelType } from 'lib/sentry/types';
import { Routes } from 'router/routes';
import { Theme } from 'themes/contracts';

export interface AppProps {
  theme: Theme;
}

export const App: FC<AppProps> = ({ theme }) => {
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    chatraWidget.add(theme.colors, language);
  }, [theme.colors, language]);

  const app = (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ErrorBoundary
        fallback={<InternalErrorBlock />}
        severity={SeverityLevelType.Fatal}
      >
        <AuthServiceProvider>
          <Routes />
        </AuthServiceProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );

  if (
    process.env.REACT_APP_RECAPTCHA_DISABLED === '1' ||
    process.env.REACT_APP_RECAPTCHA_SITEKEY_V3 == null
  ) {
    return app;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY_V3}
    >
      {app}
    </GoogleReCaptchaProvider>
  );
};

export default App;
