import { PayMethodNetwork } from './constants';

export const getNetworkBadgeName = (
  payMethodNetwork: PayMethodNetwork | string
) => {
  switch (payMethodNetwork) {
    case PayMethodNetwork.ETHEREUM:
      return 'ERC20';
    case PayMethodNetwork.BUSD:
    case PayMethodNetwork.BNB:
      return 'BEP20';
    case PayMethodNetwork.TRC20:
      return 'TRC20';
    default:
      return '';
  }
};

export function formatEmail(email: string): string {
  // Split the email address into username and domain
  const [username, domain] = email.split('@');

  // Calculate the number of characters to reveal at the beginning and end of the username
  const charactersToReveal = Math.min(
    Math.max(username.length - 6, 1),
    username.length
  );

  // Calculate the number of characters to mask in the middle of the username (maximum 3 characters)
  const charactersToMask = Math.min(
    Math.max(username.length - charactersToReveal, 0),
    3
  );

  // Mask a portion of the username with asterisks
  const maskedUsername =
    username.slice(0, charactersToReveal) +
    '*'.repeat(charactersToMask) +
    username.slice(-Math.min(charactersToReveal, 3));

  return `${maskedUsername}@${domain}`;
}

export function bodyScrollLockToggle(lock?: boolean) {
  if (lock && document.body.style.overflow !== 'hidden') {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
}

export const enumValues = <T extends object>(enumObj: T): T[keyof T][] => {
  return Object.values(enumObj);
};
