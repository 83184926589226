import React, { Fragment, useCallback, useContext, useMemo } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-navi';

import useAnimationTransition from 'hooks/animationTransition';
import { useApi } from 'hooks/api';
import { useTheme } from 'hooks/theme';
import { useOutsideClickVisibility } from 'hooks/visibility';

import { logout } from 'api/auth';

import { getLoggedEmail } from 'helpers/auth';
import { VerificationLevel, VerifyStatus } from 'helpers/constants';
import { showTooltip } from 'helpers/tooltip';
import { translationFactory } from 'helpers/trans';

import { Responsive, ResponsiveMediaType } from 'components/Responsive';
import { Button, ButtonType } from 'components/UI/Button';
import { Icon } from 'components/UI/Icon';
import { SideBar } from 'components/UI/SideBar';
import { UserInitials } from 'components/userInitials';
import { UserStatusTag } from 'components/userStatusTag';

import { AuthServiceContext } from 'modules/authService/context';
import { TooltipTypes } from 'modules/globalTooltip';

import { getProfileItems } from './data';
import * as ScProfileDropdown from './styled';
import { ScUserProfileBtn } from './styled';

const ProfileDropdown = () => {
  const { t } = useTranslation('profile');
  const {
    images,
    isHideBonuses,
    isHidePartners,
    colors: { icons, text },
  } = useTheme();

  const { userInfo } = useContext(AuthServiceContext);
  const loggedEmail = getLoggedEmail();

  const [logoutRequest, { pending }] = useApi(logout);

  async function handleLogoutClick() {
    const { error } = await logoutRequest().request;
    if (error != null) {
      showTooltip({
        type: TooltipTypes.ERROR,
        body: translationFactory('common:error.unknown'),
      });
    } else {
      showTooltip({
        type: TooltipTypes.SUCCESS,
        body: translationFactory('common:header.logoutMsg'),
      });
    }
  }

  const verifyStatus = useMemo(() => {
    return userInfo?.verificationPendingStatus.length
      ? VerifyStatus.VERIFYING
      : userInfo?.verificationLevel === VerificationLevel.LEVEL_2
        ? VerifyStatus.LEVEL_2
        : userInfo?.verificationLevel === VerificationLevel.Verified
          ? VerifyStatus.VERIFIED
          : VerifyStatus.NOT_VERIFIED;
  }, [userInfo]);

  const profileItems = getProfileItems(
    images,
    t,
    verifyStatus || VerifyStatus.NOT_VERIFIED,
    text.primary,
    isHideBonuses,
    isHidePartners
  );

  const { ref, isComponentVisible, setIsComponentVisible } =
    useOutsideClickVisibility(false);
  const { transitionState, isAnimationActive } = useAnimationTransition(
    2000,
    isComponentVisible
  );

  const toggle = useCallback(() => {
    setIsComponentVisible((i) => !i);
  }, [setIsComponentVisible]);

  return (
    <ScProfileDropdown.Container>
      <ScProfileDropdown.Mail>
        <p>{loggedEmail}</p>
        {userInfo?.turnover != null && !isHideBonuses && (
          <UserStatusTag turnover={userInfo?.turnover} />
        )}
      </ScProfileDropdown.Mail>

      <ScProfileDropdown.DropdownTrigger
        className={cx('', isComponentVisible && 'active')}
        ref={ref}
      >
        <div onClick={toggle}>
          <UserInitials
            verified={
              userInfo?.verificationLevel === VerificationLevel.NOT_VERIFIED
                ? false
                : undefined
            }
          />
          <Icon name="Arrow" fill={icons.primary} />
        </div>

        <Responsive
          desktop={
            <Fragment>
              {(transitionState || isAnimationActive) && (
                <ScProfileDropdown.Dropdown
                  className={cx('', isAnimationActive && 'open')}
                >
                  <ScProfileDropdown.DropdownItem>
                    <Link href="/profile/dashboard" onClick={toggle}>
                      <span className="dropdownItem__link">
                        <Icon name="DashboardIcon" fill={icons.primary} />
                        <span>{t('common:titles.dashboard')}</span>
                      </span>
                    </Link>
                  </ScProfileDropdown.DropdownItem>

                  <hr />

                  <div className="dropdown__list">
                    {profileItems.map(
                      ({ content, href, image, classNames, badge }, index) => (
                        <ScProfileDropdown.DropdownItem
                          key={index}
                          className={cx(classNames)}
                        >
                          <Link href={href} onClick={toggle}>
                            <span className="dropdownItem__link">
                              {image}
                              <span>{content}</span>
                            </span>

                            {badge && (
                              <span className="dropdownItem__badge">
                                {badge}
                              </span>
                            )}
                          </Link>
                        </ScProfileDropdown.DropdownItem>
                      )
                    )}
                  </div>

                  <hr />

                  <div>
                    <ScProfileDropdown.DropdownItem
                      onClick={handleLogoutClick}
                      className={cx(pending && 'disabled')}
                    >
                      <p className="dropdownItem__logOut">{t('logOut')}</p>
                    </ScProfileDropdown.DropdownItem>
                  </div>
                </ScProfileDropdown.Dropdown>
              )}
            </Fragment>
          }
          tablet={
            <SideBar isOpen={isComponentVisible}>
              <ScProfileDropdown.SideBarContent>
                <div>
                  <ScUserProfileBtn
                    className={cx('', isComponentVisible && 'active')}
                  >
                    <UserInitials
                      verified={
                        userInfo?.verificationLevel ===
                        VerificationLevel.NOT_VERIFIED
                          ? false
                          : undefined
                      }
                    />
                    <Icon name="Arrow" fill={icons.primary} />
                  </ScUserProfileBtn>

                  <ScProfileDropdown.DropdownItem>
                    <Link href="/profile/dashboard">
                      <span className="dropdownItem__link">
                        <Icon name="DashboardIcon" fill={icons.primary} />
                        <span>{t('common:titles.dashboard')}</span>
                      </span>
                    </Link>
                  </ScProfileDropdown.DropdownItem>

                  {profileItems.map(
                    ({ content, href, image, classNames, badge }, index) => (
                      <ScProfileDropdown.DropdownItem
                        key={index}
                        className={cx(classNames)}
                      >
                        <Link href={href}>
                          <span className="dropdownItem__link">
                            {image}
                            <span>{content}</span>
                          </span>

                          {badge && (
                            <span className="dropdownItem__badge">{badge}</span>
                          )}
                        </Link>
                      </ScProfileDropdown.DropdownItem>
                    )
                  )}
                </div>

                <Button onClick={logout} btnType={ButtonType.PRIMARY}>
                  {t('logOut')}
                </Button>
              </ScProfileDropdown.SideBarContent>
            </SideBar>
          }
          mobile={ResponsiveMediaType.TABLET}
        />
      </ScProfileDropdown.DropdownTrigger>
    </ScProfileDropdown.Container>
  );
};

export default ProfileDropdown;
