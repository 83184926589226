import * as React from 'react';
import { SVGProps } from 'react';

const SvgDepositWalletIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#E8EAED"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 20c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 18V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 4 4h5v2H4v12h16V6h-5V4h5c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 20H4Zm8-4.6-5-5L8.4 9l2.6 2.6V4h2v7.6L15.6 9l1.4 1.4-5 5Z" />
    </svg>
  );
};
export default SvgDepositWalletIcon;
