import { CurrentUser } from 'api/user';

export type Subscriber = (isAuthenticated: boolean) => void;

export class AuthService {
  public isAuthenticated: boolean;
  private subscribers: Subscriber[];
  public userInfo?: CurrentUser;

  constructor(isAuthenticated: boolean, userInfo?: CurrentUser) {
    this.isAuthenticated = isAuthenticated;
    this.subscribers = [];
    this.userInfo = userInfo ?? this.loadUserInfoFromSession();
  }

  private notify = () => {
    this.subscribers.forEach((subscriber) => subscriber(this.isAuthenticated));
  };

  login = () => {
    this.setIsAuthenticated(true);
  };

  logout = () => {
    this.clearUserInfoFromSession();
    this.setUserInfo(undefined);
    this.setIsAuthenticated(false);
  };

  setIsAuthenticated = (isAuthenticated: boolean) => {
    this.isAuthenticated = isAuthenticated;
    this.notify();
  };

  setUserInfo = (userInfo?: CurrentUser) => {
    this.userInfo = userInfo;
    if (userInfo) {
      this.saveUserInfoToSession(userInfo);
    } else {
      this.clearUserInfoFromSession();
    }
  };

  subscribe = (subscriber: Subscriber) => {
    this.subscribers.push(subscriber);
    return () => {
      const subscriberIndex = this.subscribers.findIndex(
        (c) => c === subscriber
      );
      if (subscriberIndex === -1) return;
      this.subscribers.splice(subscriberIndex, 1);
    };
  };

  private saveUserInfoToSession = (userInfo: CurrentUser) => {
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
  };

  private loadUserInfoFromSession = (): CurrentUser | undefined => {
    const storedUserInfo = sessionStorage.getItem('userInfo');
    return storedUserInfo ? JSON.parse(storedUserInfo) : undefined;
  };

  private clearUserInfoFromSession = () => {
    sessionStorage.removeItem('userInfo');
  };
}
