import { useEffect, useRef, useState } from 'react';

const useAnimationTransition = (
  timeoutDuration: number,
  isVisible: boolean
) => {
  const [transitionState, setTransitionState] = useState<boolean>(false);
  const [isAnimationActive, setAnimationActive] = useState<boolean>(isVisible);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      // Skip animation setup on the first render
      firstRender.current = false;
      return;
    }

    if (isVisible) {
      setAnimationActive(true);
      setTransitionState(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setTransitionState(false);
      }, timeoutDuration);
    } else {
      setAnimationActive(false);
      setTransitionState(true); // Keep animation in progress
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setTransitionState(false);
      }, timeoutDuration);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [isVisible, timeoutDuration]);

  return { transitionState, isAnimationActive };
};

export default useAnimationTransition;
