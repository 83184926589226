import React from 'react';

import { TFunction } from 'i18next';

import { VerifyStatus } from 'helpers/constants';

import { ImageMap } from 'themes/contracts';

import * as ScProfileDropdown from './styled';

export const getProfileItems = (
  images: ImageMap,
  t: TFunction,
  verifyStatus: VerifyStatus,
  fill: string,
  isHideBonuses?: true,
  isHidePartners?: true
) => {
  const verifyStatusText = () => {
    switch (verifyStatus) {
      case VerifyStatus.LEVEL_2:
      case VerifyStatus.VERIFIED:
        return t('common:verifyStatus.verified');
      case VerifyStatus.VERIFYING:
        return t('common:verifyStatus.verifying');
      case VerifyStatus.NOT_VERIFIED:
        return t('common:verifyStatus.notVerified');
    }
  };

  return [
    {
      content: t('common:titles.info'),
      href: '/profile/info',
      badge: (
        <ScProfileDropdown.ProfileVerifyStatus status={verifyStatus}>
          {verifyStatusText()}
        </ScProfileDropdown.ProfileVerifyStatus>
      ),
      image:
        verifyStatus === VerifyStatus.NOT_VERIFIED ? (
          <images.verifyProfileIcon.component />
        ) : (
          <images.profileIcon.component />
        ),
      classNames: [],
    },
    {
      content: t('common:titles.bids'),
      href: '/profile/bids',
      image: <images.exchangeHistoryIcon.component />,
      classNames: [],
    },
    ...(isHideBonuses
      ? []
      : [
          {
            content: t('common:titles.bonus'),
            href: '/profile/bonus',
            image: <images.bonusNavIcon.component />,
            classNames: [],
          },
        ]),
    ...(isHidePartners
      ? []
      : [
          {
            content: t('common:titles.partners'),
            href: '/partner/info',
            image: <images.partnersIcon.component />,
            classNames: [],
          },
        ]),
    {
      content: t('common:titles.settings'),
      href: '/profile/settings',
      image: <images.settingsIcon.component />,
      classNames: [],
    },
  ];
};
